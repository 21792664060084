import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Action } from 'history';
import dataService from 'utils/data.service';
import { create } from 'yup/lib/Reference';

const initialState = {
    invoices: [],
    status: 'idle',
    error: null
};
const API_URL = 'http://localhost:8000/api/';
let autho = '';
if (JSON.parse(localStorage.getItem('auth'))) {
    const token = JSON.parse(localStorage.getItem('auth'));
    autho = token.access;
}

const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${autho}`
};

export const fetchInvoices = createAsyncThunk('invoices/fetchInvoices', async () => {
    const response = await axios.get(`${API_URL}invoice/`, { headers });
    return response.data;
});
export const addNewInvoice = createAsyncThunk('invoice/newInvoice', async (initialInvoice) => {
    const response = await axios.post(`${API_URL}invoice/`, initialInvoice);
    return response.data;
});

const invoiceSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchInvoices.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchInvoices.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.invoices = state.invoices.concat(action.payload);
            })
            .addCase(fetchInvoices.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});
export default invoiceSlice.reducer;
// reusable selectors
export const selectAllInvoices = (state) => state.invoices.invoices;
export const selectInvoiceById = (state, invoiceId) => state.invoices.invoices.find((invoice) => invoice.id === invoiceId);
