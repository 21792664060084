/* eslint-disable import/prefer-default-export */
import { apiSlice } from '../../../api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: 'auth/login/',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        addNewAccount: builder.mutation({
            query: (initialAccount) => ({
                url: '/auth/register/',
                method: 'POST',
                body: initialAccount
            }),
            invalidatesTags: ['Account']
        }),
        addNewUser: builder.mutation({
            query: (initialUser) => ({
                url: '/auth/adduser/',
                method: 'POST',
                body: initialUser
            }),
            invalidatesTags: ['Users']
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: '/user/',
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['Users']
        }),
        getUsers: builder.query({
            query: () => '/user/',
            providesTags: ['Users']
        }),
        getUser: builder.query({
            query: (userId) => `/user/${userId}`,
            providesTags: ['Users']
        })
    })
});

export const {
    useLoginMutation,
    useAddNewAccountMutation,
    useAddNewUserMutation,
    useGetUsersQuery,
    useGetUserQuery,
    useUpdateUserMutation
} = authApiSlice;
