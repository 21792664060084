import { SubdirectoryArrowRightSharp } from '@mui/icons-material';
import axios from 'axios';
import { useEffect } from 'react';
// import { useAxios } from 'use-axios-client';

const token = JSON.parse(localStorage.getItem('auth'));
let autho;
let Business;
let User;
const formData = new FormData();
const API_URL = 'http://localhost:8000/api/';
if (token !== null) {
    autho = token.access;
    Business = token.business;
    User = token.user;
}
const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${autho}`
};
// const axiosInstance = axios.create({
// baseURL: 'http://localhost:8000/api/',
// headers
// });
const getData = (url) => {
    axios
        .get(`${API_URL}${url}`, {
            headers
        })
        .then((response) => response.data);
};
const postCargo = (business, cargoName, Description, UOM, cat, subCat) => {
    const category = {
        id: cat
    };
    const uom = {
        id: UOM
    };
    const subCategory = {
        id: subCat
    };
    axios
        .post(
            `${API_URL}cargo/`,
            {
                Business: business.id,
                user: User.id,
                cargo_name: cargoName,
                description: Description,
                uom: UOM,
                category: cat,
                sub_category: subCat
            },
            {
                headers
            }
        )
        .then((response) => response.data);
};
const postCategory = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                Business: Business.id,
                category_name: values.category_name,
                description: values.description,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postsubCategory = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                Business: Business.id,
                cargo_category: values.category,
                sub_category_name: values.sub_category_name,
                description: values.description,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postUom = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                Business: Business.id,
                uom: values.uom,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postCrew = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                Crew_category: values.Crew_category,
                first_name: values.first_name,
                last_name: values.last_name,
                mobile_no: values.mobile_no,
                avatar: values.avatar,
                national_id: values.national_id,
                passport_no: values.passport_no,
                driving_license: values.driving_license,
                PPFile: values.PPFile,
                DLFile: values.DLFile,
                NIDFile: values.NIDFile,
                user: User.id,
                Business: Business.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postCrewCategory = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                Business: Business.id,
                category_name: values.category_name,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postTruck = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                truck_reg: values.truck_reg,
                truck_make: values.truck_make,
                trailer: values.trailer,
                tare_weight: values.tare_weight,
                active: values.active,
                Business: Business.id,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postTruckOwner = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                mobile_no: values.mobile_no,
                address: values.address,
                Business: Business.id,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postTruckInspection = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                inspection_details: values.inspection_details,
                inspection_date: values.inspection_date,
                next_inspection_date: values.next_inspection_date,
                truck: values.truck,
                Business: Business.id,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postTruckInsurance = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                insurance_details: values.insurance_details,
                expiry_date: values.expiry_date,
                truck: values.truck,
                Business: Business.id,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postTrailerType = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                Business: Business.id,
                type_name: values.type_name,
                description: values.description,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postTruckMake = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                Business: Business.id,
                make: values.make,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postTrailer = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                Business: Business.id,
                user: User.id,
                trailer_reg: values.trailer_reg,
                trailer_type: values.trailer_type,
                active: values.active
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postDistanceUom = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                Business: Business.id,
                uom: values.uom,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postConsignment = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                description: values.description,
                client: values.client,
                route: values.route,
                cargo: values.cargo,
                category: values.category,
                tonnage: values.tonnage,
                cbm: values.cbm,
                trips_no: values.trips_no,
                Business: Business.id,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log(response.data);
            }
            return response.data;
        });
};
const postTrip = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                truck: values.truck,
                consignment: values.consignment,
                route: values.route,
                cargo_quantity: values.cargo_quantity,
                containerized: values.containerized,
                tonnage: values.tonnage,
                cbm: values.cbm,
                trip_status: values.trip_status,
                Business: Business.id,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                // console.log(response.data);
            }
            return response.data;
        });
};
const editTrip = (values, url) => {
    axios
        .patch(
            `${API_URL}${url}${values.id}/`,
            {
                tonnage: values.tonnage,
                trip_status: values.trip_status,
                route: values.route,
                containerized: values.containerized
            },
            {
                id: values.id,
                truck: values.truck,
                cargo_quantity: values.cargo_quantity,
                consignment: values.consignment,
                cbm: values.cbm,
                Business: Business.id,
                user: User.id,
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                // console.log(response.data);
            }
            return response.data;
        });
};
const postInvoice = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                client: values.client,
                consignment: values.consignment,
                invoice_amount: values.invoice_amount,
                invoice_date: values.invoice_date,
                Business: Business.id,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log('Success');
            }
            return response.data;
        });
};
const postReceipt = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                description: values.description,
                receipt_date: values.receipt_date,
                Business: Business.id,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log('Success');
            }
            return response.data;
        });
};
const postReceiptDetail = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                Receipt: values.Receipt,
                invoice: values.invoice,
                receipt_amount: values.receipt_amount,
                description: values.description
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log('Success');
            }
            return response.data;
        });
};
const postRoute = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                origin_place: values.origin_place,
                destination_place: values.destination_place,
                distance: values.distance,
                description: values.description,
                uom: values.uom,
                Business: Business.id,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log('Success');
            }
            return response.data;
        });
};
const PostClient = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                mobile_no: values.mobile_no,
                company_name: values.company_name,
                address: values.address,
                Business: Business.id,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log('Success');
            }
            return response.data;
        });
};
const PostContainerDepot = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                depot_name: values.depot_name,
                contact_person: values.contact_person,
                email: values.email,
                mobile_no: values.mobile_no,
                company_name: values.company_name,
                address: values.address,
                Business: Business.id,
                user: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                console.log('Success');
            }
            return response.data;
        });
};
const PostExpense = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                description: values.description,
                requested_by: values.requested_by,
                Business: Business.id,
                created_by: User.id
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                return response.data;
            }
            return response.data;
        });
};
const postExpenseDetail = (values, url) => {
    axios
        .post(
            `${API_URL}${url}`,
            {
                expense: values.expense,
                truck: values.truck,
                description: values.description,
                amount: values.amount
            },
            {
                headers
            }
        )
        .then((response) => {
            if (response.data) {
                // Update total amount in Expense
                const data = response.data;
                axios.put(`${API_URL}expenses/`, {
                    id: data.expense,
                    total_amount: data.amount
                });
            }
            return response.data;
        });
};
export default {
    getData,
    postCargo,
    postCategory,
    postsubCategory,
    postUom,
    postCrew,
    postCrewCategory,
    postTruck,
    postTrailerType,
    postTruckMake,
    postTrailer,
    postDistanceUom,
    postConsignment,
    postTrip,
    editTrip,
    postInvoice,
    postRoute,
    PostClient,
    PostExpense,
    postExpenseDetail,
    postReceipt,
    postReceiptDetail,
    PostContainerDepot
};
