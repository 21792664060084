import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RequireAuth from 'views/pages/authentication/RequireAuth';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// Consignment routing
const ConsigmentList = Loadable(lazy(() => import('views/consignment/list')));
const CreateConsignment = Loadable(lazy(() => import('views/consignment/create')));
const EditConsignment = Loadable(lazy(() => import('views/consignment/Edit')));
const ConsignmentCategory = Loadable(lazy(() => import('views/consignment/category')));
const AddTrip = Loadable(lazy(() => import('views/consignment/trips/trip')));
const EditTrip = Loadable(lazy(() => import('views/consignment/trips/Edit')));
const TripList = Loadable(lazy(() => import('views/consignment/trips/trips')));
const ShuntingList = Loadable(lazy(() => import('views/shunting/list')));
const CreateShunting = Loadable(lazy(() => import('views/shunting/create')));
const EditShunting = Loadable(lazy(() => import('views/shunting/edit')));
// Containerdepot routing
const ContainerdepotList = Loadable(lazy(() => import('views/pages/containerdepot/list')));
const CreateContainerdepot = Loadable(lazy(() => import('views/pages/containerdepot/create')));
// const EditContainerdepot = Loadable(lazy(() => import('views/pages/containerdepot/')));
// Finance routing
const InvoiceList = Loadable(lazy(() => import('views/finance/Invoices/invoices')));
const CreateInvoice = Loadable(lazy(() => import('views/finance/Invoices/invoice')));
const ReceiptList = Loadable(lazy(() => import('views/finance/receipts/receipts')));
const CreateReceipt = Loadable(lazy(() => import('views/finance/receipts/create')));
const ReceiptDetails = Loadable(lazy(() => import('views/finance/receipts/details')));
const ExpenseList = Loadable(lazy(() => import('views/pages/fleet/expenses/list')));
const CreateExpense = Loadable(lazy(() => import('views/pages/fleet/expenses/create')));
const ExpenseDetails = Loadable(lazy(() => import('views/pages/fleet/expenses/details')));
// Clients routing
const ClientsList = Loadable(lazy(() => import('views/pages/clients/list')));
const CreateClient = Loadable(lazy(() => import('views/pages/clients/create')));
// User Routing
const UserList = Loadable(lazy(() => import('views/account/users/list')));
const CreateUser = Loadable(lazy(() => import('views/account/users/create')));
const EditUser = Loadable(lazy(() => import('views/account/users/edit')));
// Cargo routing
const CargoList = Loadable(lazy(() => import('views/pages/cargo/list')));
const CreateCargo = Loadable(lazy(() => import('views/pages/cargo/create')));
const CargoSettings = Loadable(lazy(() => import('views/pages/cargo/settings')));
const CreateCategory = Loadable(lazy(() => import('views/pages/cargo/category')));
const CreateSubCategory = Loadable(lazy(() => import('views/pages/cargo/subcategory')));
const CreateUOM = Loadable(lazy(() => import('views/pages/cargo/uom')));
// Crew Routing
const CrewList = Loadable(lazy(() => import('views/pages/crew/list')));
const AddCrew = Loadable(lazy(() => import('views/pages/crew/create')));
const CreateCrewCategory = Loadable(lazy(() => import('views/pages/crew/category')));
const EditCrew = Loadable(lazy(() => import('views/pages/crew/edit')));
// Fleet Routing
const FleetList = Loadable(lazy(() => import('views/pages/fleet/list')));
const AddTruck = Loadable(lazy(() => import('views/pages/fleet/create')));
const EditTruck = Loadable(lazy(() => import('views/pages/fleet/Edit')));
const TruckMake = Loadable(lazy(() => import('views/pages/fleet/truckmake')));
// const TruckSize = Loadable(lazy(() => import('views/pages/fleet/trucksize')));
const TrailerType = Loadable(lazy(() => import('views/pages/fleet/trailertype')));
const AddTrailer = Loadable(lazy(() => import('views/pages/fleet/trailer')));
const AddTruckOwner = Loadable(lazy(() => import('views/pages/fleet/AddOwner')));
// const OwnerList = Loadable(lazy(() => import('views/pages/fleet/owner/list')));
const CargoInsuranceList = Loadable(lazy(() => import('views/pages/fleet/Insurance/list')));
const AddCargoInsurance = Loadable(lazy(() => import('views/pages/fleet/Insurance/AddInsurance')));
const EditCargoInsurance = Loadable(lazy(() => import('views/pages/fleet/Insurance/Edit')));
const AddTruckInspection = Loadable(lazy(() => import('views/pages/fleet/AddInspection')));
// Routes routing
const RouteList = Loadable(lazy(() => import('views/pages/route/routes')));
const RouteRatesList = Loadable(lazy(() => import('views/pages/route/rates')));
const RouteUOM = Loadable(lazy(() => import('views/pages/route/uom')));
const AddRoute = Loadable(lazy(() => import('views/pages/route/create')));
const AddRouteRate = Loadable(lazy(() => import('views/pages/route/NewRate')));
// Fuel Rate
const AddFuelRate = Loadable(lazy(() => import('views/fuelrates/Add')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <RequireAuth />,
    children: [
        {
            path: '/',
            element: <MainLayout />,
            children: [
                {
                    path: '/dashboard/default',
                    element: <DashboardDefault />
                },
                {
                    path: '/',
                    element: <DashboardDefault />
                },
                {
                    path: '/operations/consignments',
                    element: <ConsigmentList />
                },
                {
                    path: '/operations/consignment/create',
                    element: <CreateConsignment />
                },
                {
                    path: '/operations/consignment/edit',
                    element: <EditConsignment />
                },
                {
                    path: '/operations/consignment/category',
                    element: <ConsignmentCategory />
                },
                {
                    path: '/operations/shunting',
                    element: <ShuntingList />
                },
                {
                    path: '/operations/shunting/create',
                    element: <CreateShunting />
                },
                {
                    path: '/operations/shunting/edit',
                    element: <EditShunting />
                },
                {
                    path: '/finance/invoice/list',
                    element: <InvoiceList />
                },
                {
                    path: '/finance/invoice/',
                    element: <CreateInvoice />
                },
                {
                    path: '/finance/receipt/list',
                    element: <ReceiptList />
                },
                {
                    path: '/finance/receipt/',
                    element: <CreateReceipt />
                },
                {
                    path: '/finance/receipt/details/',
                    element: <ReceiptDetails />
                },
                {
                    path: '/operations/trip',
                    element: <AddTrip />
                },
                {
                    path: '/operations/trip/edit',
                    element: <EditTrip />
                },
                {
                    path: '/operations/trips',
                    element: <TripList />
                },
                {
                    path: '/settings/clients/list',
                    element: <ClientsList />
                },
                {
                    path: '/settings/client/create',
                    element: <CreateClient />
                },
                {
                    path: 'account/settings',
                    element: <UserList />
                },
                {
                    path: 'account/settings/user/create',
                    element: <CreateUser />
                },
                {
                    path: 'account/settings/user/edit',
                    element: <EditUser />
                },
                {
                    path: '/settings/containerdepot/create',
                    element: <CreateContainerdepot />
                },
                {
                    path: '/settings/containerdepots/list',
                    element: <ContainerdepotList />
                },
                {
                    path: '/settings/cargo/list',
                    element: <CargoList />
                },
                {
                    path: '/settings/cargo/create',
                    element: <CreateCargo />
                },
                {
                    path: '/settings/cargo/settings',
                    element: <CargoSettings />
                },
                {
                    path: '/settings/cargo/category',
                    element: <CreateCategory />
                },
                {
                    path: '/settings/cargo/subcategory',
                    element: <CreateSubCategory />
                },
                {
                    path: '/settings/cargo/uom',
                    element: <CreateUOM />
                },
                {
                    path: '/operations/crew',
                    element: <CrewList />
                },
                {
                    path: '/operations/crew/create',
                    element: <AddCrew />
                },
                {
                    path: '/operations/crew/edit',
                    element: <EditCrew />
                },
                {
                    path: '/operations/crew/category',
                    element: <CreateCrewCategory />
                },
                {
                    path: '/operations/fleet',
                    element: <FleetList />
                },
                {
                    path: '/operations/fleet/create',
                    element: <AddTruck />
                },
                {
                    path: '/operations/fleet/edit',
                    element: <EditTruck />
                },
                {
                    path: '/operations/fleet/truckmake',
                    element: <TruckMake />
                },
                {
                    path: '/operations/fleet/trailertype',
                    element: <TrailerType />
                },
                {
                    path: '/operations/fleet/trailer',
                    element: <AddTrailer />
                },
                {
                    path: '/operations/fleet/addowner',
                    element: <AddTruckOwner />
                },
                /*
                {
                    path: '/operations/fleet/owner/list',
                    element: <OwnerList />
                },
                */
                {
                    path: '/settings/cargoinsurance/list',
                    element: <CargoInsuranceList />
                },
                {
                    path: '/settings/cargoinsurance/Add',
                    element: <AddCargoInsurance />
                },
                {
                    path: '/settings/cargoinsurance/Edit',
                    element: <EditCargoInsurance />
                },
                {
                    path: '/operations/fleet/inspection',
                    element: <AddTruckInspection />
                },
                {
                    path: '/finance/expenses',
                    element: <ExpenseList />
                },
                {
                    path: '/finance/expense',
                    element: <CreateExpense />
                },
                {
                    path: '/finance/expense/details',
                    element: <ExpenseDetails />
                },
                {
                    path: '/settings/route/routes',
                    element: <RouteList />
                },
                {
                    path: '/settings/route/rates',
                    element: <RouteRatesList />
                },
                {
                    path: '/settings/route/uom',
                    element: <RouteUOM />
                },
                {
                    path: '/settings/route/create',
                    element: <AddRoute />
                },
                {
                    path: '/settings/route/NewRate',
                    element: <AddRouteRate />
                },
                {
                    path: '/settings/fuelrate/new',
                    element: <AddFuelRate />
                }
            ]
        }
    ]
};
export default MainRoutes;
