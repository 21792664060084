// assets
import { IconKey, Luggage } from '@tabler/icons';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import TakeoutDiningOutlinedIcon from '@mui/icons-material/TakeoutDiningOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';
import RvHookupOutlinedIcon from '@mui/icons-material/RvHookupOutlined';
// constant
const icons = {
    IconKey,
    AccountBoxOutlinedIcon,
    LuggageOutlinedIcon,
    GroupsOutlinedIcon,
    NavigationOutlinedIcon,
    RvHookupOutlinedIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Settings',
    caption: 'system settings',
    type: 'group',
    children: [
        /*
        {
            id: 'client3',
            title: 'Clients',
            type: 'item',
            url: '/settings/clients/list',
            icon: icons.AccountBoxOutlinedIcon
        },
        
        {
            id: 'truckowner',
            title: 'Truck Owner',
            type: 'item',
            url: '/operations/fleet/owner/list',
            icon: icons.AccountBoxOutlinedIcon
        },
        */
        {
            id: 'fleet',
            title: 'Fleet',
            type: 'item',
            url: '/operations/fleet',
            icon: icons.RvHookupOutlinedIcon
        },
        {
            id: 'cargoinsurance',
            title: 'Cargo Insurance',
            type: 'item',
            url: '/settings/cargoinsurance/list',
            icon: icons.AccountBoxOutlinedIcon
        },
        /* 
        {
            id: 'truckinspection',
            title: 'Truck Inspection',
            type: 'item',
            url: '/settings/truckinspection/list',
            icon: icons.AccountBoxOutlinedIcon
        },
       
        {
            id: 'containerdepot',
            title: 'Container Depots',
            type: 'item',
            url: '/settings/containerdepots/list',
            icon: icons.AccountBoxOutlinedIcon
        },
        */

        {
            id: 'cargo',
            title: 'Cargo',
            type: 'collapse',
            icon: icons.LuggageOutlinedIcon,
            children: [
                {
                    id: 'cargo3',
                    title: 'Cargo',
                    type: 'item',
                    url: '/settings/cargo/list'
                },
                {
                    id: 'cargo4',
                    title: 'Settings',
                    type: 'item',
                    url: '/settings/cargo/settings'
                }
            ]
        },
        {
            id: 'route',
            title: 'Routes',
            type: 'item',
            url: '/settings/route/routes',
            icon: icons.NavigationOutlinedIcon
        },
        {
            id: 'rates',
            title: 'Rates',
            type: 'item',
            url: '/settings/route/rates',
            icon: icons.NavigationOutlinedIcon
        }
    ]
};

export default pages;
