import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';
import jwtDecode from 'jwt-decode';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../hooks/useAuth';

import { selectCurrentUser, selectCurrentBusiness } from '../../../views/pages/authentication/authSlice';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    /** Load user profile information */
    const navigate = useNavigate();
    const auth = useAuth();
    // const token = JSON.parse(localStorage.getItem('auth'));
    // const user = useSelector(selectCurrentUser);
    const business = useSelector(selectCurrentBusiness);
    let Business;
    if (business !== null) {
        Business = business.business_name;
    } else {
        // redirect to login currentUser.Business </ButtonBase>
    }
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            {Business}
        </ButtonBase>
    );
};
export default LogoSection;
