// assets
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import CommuteOutlinedIcon from '@mui/icons-material/CommuteOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

// constant
const icons = { GroupWorkOutlinedIcon, CommuteOutlinedIcon, DescriptionOutlinedIcon, AccountBalanceWalletOutlinedIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const finances = {
    id: 'finance',
    title: 'Finances',
    caption: 'income & expenses',
    type: 'group',
    children: [
        {
            id: 'invoice',
            title: 'Invoices',
            type: 'item',
            url: '/finance/invoice/list',
            icon: icons.DescriptionOutlinedIcon
        },
        {
            id: 'receipt',
            title: 'Receipts',
            type: 'item',
            url: '/finance/receipt/list',
            icon: icons.DescriptionOutlinedIcon
        },
        {
            id: 'expenses1',
            title: 'Expenses',
            type: 'item',
            url: '/finance/expenses',
            icon: icons.AccountBalanceWalletOutlinedIcon
        }
    ]
};

export default finances;
