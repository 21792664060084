import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Action } from 'history';
import dataService from 'utils/data.service';
import { create } from 'yup/lib/Reference';

const initialState = {
    clients: [],
    status: 'idle',
    error: null
};
const API_URL = 'http://localhost:8000/api/';
let autho = '';
if (JSON.parse(localStorage.getItem('auth'))) {
    const token = JSON.parse(localStorage.getItem('auth'));
    autho = token.access;
}

const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${autho}`
};

export const fetchClients = createAsyncThunk('clients/fetchClients', async () => {
    const response = await axios.get(`${API_URL}client/`, { headers });
    return response.data;
});
export const addNewClient = createAsyncThunk('clients/newClient', async (initialClient) => {
    const response = await axios.post(`${API_URL}client/`, initialClient);
    return response.data;
});

const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchClients.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchClients.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.clients = state.clients.concat(action.payload);
            })
            .addCase(fetchClients.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});
export default clientsSlice.reducer;
// reusable selectors
export const selectAllClients = (state) => state.clients.clients;
export const selectClientById = (state, clientId) => state.clients.clients.find((client) => client.id === clientId);
