// import { createStore } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
// import reducer from './reducer';
import customizationReducer from './customizationReducer';
import { apiSlice } from 'api/apiSlice';
import authReducer from '../views/pages/authentication/authSlice';
import consignmentsReducer from '../views/consignment/consignmentsSlice';
import authenticationReducer from 'views/pages/authentication/authenticationSlice';
import invoiceReducer from '../views/finance/Invoices/invoiceSlice';
import clientsReducer from '../views/pages/clients/clientsSlice';
import { loadState, saveState } from 'localStorage';

// ==============================|| REDUX - MAIN STORE ||============================== //

/* export default configureStore({
    reducer: {
        customization: customizationReducer,
        consignments: consignmentsReducer,
        invoices: invoiceReducer,
        clients: clientsReducer,
        authentication: authenticationReducer
    }
}); */
const persistedState = loadState();
const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        customization: customizationReducer
    },
    persistedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
});
const { dispatch } = store;
store.subscribe(() => {
    saveState(store.getState());
});
setupListeners(store.dispatch);
export { store, dispatch };
