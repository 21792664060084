import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { sub } from 'date-fns';
import axios from 'axios';

const initialState = {
    consignments: [],
    status: 'idle',
    error: null
};
const API_URL = 'http://localhost:8000/api/';
let autho = '';
if (JSON.parse(localStorage.getItem('auth'))) {
    const token = JSON.parse(localStorage.getItem('auth'));
    autho = token.access;
}

const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${autho}`
};

export const fetchConsignments = createAsyncThunk('consignments/fetchConsignments', async () => {
    const response = await axios.get(`${API_URL}consignment/`, { headers });
    return response.data;
});
export const addNewconsignment = createAsyncThunk(
    'posts/addNewconsignment',
    // The payload creator receives the partial `{title, content, user}` object
    async (initialConsignment) => {
        // We send the initial data to the fake API server
        const response = await axios.post(`${API_URL}consignment/`, initialConsignment);
        // The response includes the complete post object, including unique ID
        return response.data;
    }
);

const consignmentsSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        consignmentUpdated(state, action) {
            const { id, deliveryNo, VehicleNo, route, cargo, Business, user } = action.payload;
            const existingConsignment = state.consignments.find((consignment) => consignment.id === id);
            if (existingConsignment) {
                existingConsignment.deliveryNo = deliveryNo;
                existingConsignment.VehicleNo = VehicleNo;
                existingConsignment.route = route;
                existingConsignment.cargo = cargo;
                existingConsignment.Business = Business;
                existingConsignment.user = user;
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchConsignments.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchConsignments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Add any fetched posts to the array
                state.consignments = state.consignments.concat(action.payload);
            })
            .addCase(fetchConsignments.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addNewconsignment.fulfilled, (state, action) => {
                // We can directly add the new post object to our posts array
                state.consignments.push(action.payload);
            });
    }
});
export const { consignmentUpdated } = consignmentsSlice.actions;
export default consignmentsSlice.reducer;
// Reusable selectors
export const selectAllConsignments = (state) => state.consignments.consignments;
export const selectClientConsignments = (state, clientId) =>
    state.consignments.consignments.find((consignment) => consignment.client.id === clientId);
export const selectConsignmentById = (state, consignmentId) =>
    state.consignments.consignments.find((consignment) => consignment.id === consignmentId);
