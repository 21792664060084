import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { sub } from 'date-fns';
import axios from 'axios';
import authService from '../../../utils/auth.service';
import { useNavigate } from 'react-router';
import config from '../../../config';

// import {fetchUserData, login, signOut} from './authThunk';

const initialState = {
    auth: null,
    status: 'idle',
    userData: []
};
const API_URL = 'http://localhost:8000/api/auth/';

export const Login = createAsyncThunk('auth/login', async (payload) => {
    // const navigate = useNavigate();
    const response = await axios.post(`${API_URL}login/`, payload);
    localStorage.setItem('auth', JSON.stringify(response.data));
    // navigate(config.defaultPath);
    return response.data;
});
export const SignOut = createAsyncThunk('auth/logOut', async () => {
    localStorage.removeItem('auth');
});
export const register = createAsyncThunk('auth/register', async (payload) => {
    const navigate = useNavigate();
    const response = await axios.post(`${API_URL}register/`, payload);
});
export const authenticationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { user, access, refresh } = action.payload;
            state.user = user;
            state.token = access;
            state.refreshToken = refresh;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(SignOut.fulfilled, (state, action) => {
                state.loading = 'loading';
                state.userData = [];
                state.auth = null;
            })
            .addCase(Login.pending, (state, action) => {
                state.status = 'Loading';
            })
            .addCase(Login.fulfilled, (state, action) => {
                const { auth, user } = action.payload;
                state.token = auth;
                state.userData = user;
                state.status = 'succeeded';
            })
            .addCase(Login.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        // [fetchUserData.pending]: (state, action) => {
        // state.loading = true;
        // },
        // [fetchUserData.fulfilled]: (state, action) => {
        // const {accessToken, user} = action.payload;
        // state.token = accessToken;
        // state.userData = user;
        // state.loading = false;
        // },
        // [fetchUserData.rejected]: (state, action) => {
        // state.loading = false;
        // state.userData = {};
        // state.token = null;
        // }
    }
});
// export const { } = authenticationSlice.actions;
export const { setCredentials } = authenticationSlice.actions;
export default authenticationSlice.reducer;

export const selectCurrentUser = (state) => state.authentication.user;
export const selectCurrentToken = (state) => state.authentication.token;
