import React, { useState, useEffect, useContext, createContext } from 'react';
import axios from 'axios';

const authContext = createContext();
const API_URL = 'http://localhost:8000/api/auth/';
const headers = {
    'Content-Type': 'application/json'
};
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext);
// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);

    const login = (email, password) => {
        axios
            .post(`${API_URL}login/`, {
                email,
                password,
                headers
            })
            .then((response) => {
                if (response.data.access) {
                    console.log(response.data.business);
                    setUser(response.data);
                    localStorage.setItem('auth', JSON.stringify(response.data));
                }
                return response.data;
            })
            .catch((error) => {
                console.error(`Error: ${error}`);
            });
    };

    const register = (firstName, lastName, email, business, password) => {
        const Business = {
            business_name: business
        };
        axios
            .post(`${API_URL}register/`, {
                Business,
                first_name: firstName,
                last_name: lastName,
                email,
                password,
                headers
            })
            .then((response) => {
                setUser(response.data);
                return response.data;
            });
    };
    const logout = () => {
        setUser(null);
    };
    // Return the user object and auth methods
    return {
        user,
        login,
        register,
        logout
    };
}
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
