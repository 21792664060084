import axios from 'axios';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cardMediaClasses } from '@mui/material';

const API_URL = 'http://localhost:8000/api/auth/';
const headers = {
    'Content-Type': 'application/json'
};
// const CancelToken = axios.CancelToken;
// const source = CancelToken.source();
// const [user, setUser] = useState(null);
// Provider hook that creates auth object and handles state
// export default function useProviderAuth() {
const register = (firstName, lastName, email, business, password) => {
    const Business = {
        business_name: business
    };
    axios
        .post(`${API_URL}register/`, {
            Business,
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            headers
        })
        .then((response) => {
            if (response.data) {
                // console.log(response.data);
            }
            return response.data;
        });
};

const login = (email, password) => {
    axios
        .post(
            `${API_URL}login/`,
            {
                email,
                password,
                headers
            }
            // { CancelToken: source.token }
        )
        .then((response) => {
            if (response.data.access) {
                localStorage.setItem('auth', JSON.stringify(response.data));
            }
            return response.data;
        })
        .catch((error) => {
            console.error(`Error: ${error}`);
        });
};

const logout = () => {
    localStorage.removeItem('auth');
};
const getCurrentUser = () => {
    JSON.parse(localStorage.getItem('auth'));
};
const refresh = () => {
    const token = JSON.parse(localStorage.getItem('auth'));
    let refreshtoken;
    // const auth = props;
    if (token !== null) {
        refreshtoken = token.refresh;
        axios
            .post(`${API_URL}refresh/`, {
                refresh: refreshtoken,
                headers
            })
            .then((response) => {
                if (response.data.access) {
                    localStorage.setItem('access', JSON.stringify(response.data));
                }
                return response.data;
            });
    } else {
        refreshtoken = token.refresh;
    }
};
function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
// Subscribe to user on mount
// Because this sets state in the callback it will cause any ...
// ... component that utilizes this hook to re-render with the ...
// ... latest auth object.
const IsLoggedIn = () => {
    const navigate = useNavigate();
    /** Load user profile information */
    const token = JSON.parse(localStorage.getItem('auth'));
    if (token === null) {
        navigate('/pages/login/login3');
    } else {
        // check if token is expired and refresh if expired
        if (token) {
            const decodedToken = jwtDecode(token);
            console.log(decodedToken.exp);
            if (decodedToken.exp * 1000 < new Date().getTime()) logout();
        }
        const auth = refresh();
    }
};
export default {
    login,
    logout,
    register,
    getCurrentUser,
    refresh,
    IsLoggedIn
};
